<template>
  <div class="resize">
    <label class="resize-row standart-text">{{ $t('attach') }}</label>
    <div class="l-col">
      <base-select
        v-model="name"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="pools"
        placeholder="Выберите публичный образ"
        size="medium"
        field-label="IP-адреса"
        :hint="'Внимание! это действие необратимо!'"
        :required="true"
        :preselect-first="true"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'DeleteAntiddos',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      mask: '',
      lastIpSection: '',
      name: '',
    };
  },
  computed: {
    pools() {
      return this.instance.allocation_pools.map(x => x.start);
    },
    // patternIP() {
    //   return this.mask
    //     ? this.isIPv4(this.mask) && this.getLastIpSection > 0 && this.getLastIpSection < 255
    //       ? `${this.mask}${this.getLastIpSection}`
    //       : 'null'
    //     : 'null';
    // },
    // getLastIpSection() {
    //   return this.name ? +this.name.split('/').at(0).split('.').at(-1) : null;
    // },
    // getGateway() {
    //   return `${this.mask}1`;
    // },
  },
  watch: {
    // getGateway: {
    //   handler: function (event) {},
    //   immediate: true,
    // },
  },
  mounted() {
    // this.instance ? this.getMask(this.instance) : null;
    // this.getGateway();
  },
  methods: {
    // isIPv4(addr) {
    //   return addr ? /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr) : null;
    // },
    // getGateway() {
    //   this.name = `${this.mask}1`;
    // },
    // getMask(ip) {
    //   if (ip) {
    //     this.mask = ip.slice(0, ip.lastIndexOf('.') + 1);
    //     return this.mask;
    //   }
    // },
    onChange() {
      this.$emit('instance', this.name);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Удалить IP-адрес",
    "info": "Если Вы хотите изменить значение шлюза по умолчани",
    "sure": {
    "confirm": "Задать"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
