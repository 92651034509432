<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <base-button
            :icon="isIcon"
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'auto',
              container: false,
              trigger: 'click hover',
            }"
            :color="isColor"
            :disabled="isRequest"
            class="stack-info__create-btn"
            @click="publicNetworkQuota !== 0 && !isRequest ? newPublicNetwork() : ''"
            >{{ $t('newServer') }}</base-button
          >
        </div>
      </div>
      <base-empty v-if="list.length === 0" title="Новая приватная сеть" class="cloud-info__empty">
        <base-button slot="link">
          {{ $t('newServer') }}
        </base-button>
      </base-empty>
      <tariffs-table-network-public :table-head="tableHead" :type="true" />
    </page-block>
  </div>
</template>

<script>
import TariffsTableNetworkPublic from '../../Main/components/TariffsTableNetworkPublic';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
// import showErrorModal from '@/mixins/showErrorModal';
import showError from '@/mixins/showErrorModal';
import newPublicNetwork from '@/mixins/newPublicNetwork';
// import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'ViewNetworkPublic',
  components: {
    TariffsTableNetworkPublic,
    BaseEmpty,
  },
  mixins: [storeMixin, showError, newPublicNetwork],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '700px',
            maxWidth: '900px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        // {
        //   key: 'subnet',
        //   label: this.$t('tableHead.subnet'),
        //   style: {
        //     width: '200px',
        //     maxWidth: '230px',
        //   },
        // },
        // {
        //   key: 'conditions',
        //   label: this.$t('tableHead.conditions'),
        //   style: {
        //     width: '134px',
        //   },
        // },
        // {
        //   key: 'status',
        //   label: this.$t('tableHead.status'),
        //   style: {
        //     width: '134px',
        //   },
        //   sort: {
        //     prop: 'status',
        //     order: 'asc',
        //   },
        // },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '14px',
          },
        },
      ],
      isRequest: true,
      isSending: false,
    };
  },
  computed: {
    isIcon() {
      return this.publicNetworkQuota === 0 ? 'error' : '';
    },
    isColor() {
      return this.publicNetworkQuota === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.publicNetworkQuota === 0) {
        return `Вы достигли квоты по количеству публичных сетей.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return 'Создать новую подсеть';
      }
    },
    networksPublic() {
      return this.$store.state.moduleStack.networks
        .filter(x => x['router:external'] === false)
        .filter(x => x.shared === true)
        .map(x => x.id);
    },

    subnetPublic() {
      return this.$store.state.moduleStack.subnets.filter(x =>
        this.networksPublic.includes(x.network_id)
      );
    },
    publicNetworkQuota() {
      if (
        this.$store.state.moduleStack.quota_limits &&
        this.$store.state.moduleStack.quota_limits.public_network
      ) {
        return this.$store.state.moduleStack.quota_limits.public_network - this.subnetPublic.length;
      } else return -1;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Публичные подсети",
      "stats": "Статистика"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "tableHead": {
      "name": "Сеть",
      "type": "Тип",
      "conditions": "Состояние",
      "shared": "Публичная",
      "config": "Конфигурация",
      "status": "Статус",
      "subnet": "Подсеть",
      "menu": "Меню"
    },
    "sure": {
      "confirm": "Создать",
      "title": "Создать новую приватную сеть",
      "text": "Стоимость публичной сети составляет ",
      "cancel": "Отмена"
    },
    "newServer": "Создать подсеть",
    "activeUnder": "Действует до {date}",
    "quotaNetwork": "Вы достигли квоты по количеству публичных сетей, обратитесь в поддержку для изменения квоты",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

      &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        //flex: 1 1 auto;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 40px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
