<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label">{{ $t('label') }}</label>
      <base-input
        v-model="formData.name"
        :placeholder="$t('placeholder')"
        class="resize-row-input"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <label class="typo__label">{{ $t('network') }}</label>
      <base-select
        v-model="value"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :placeholder="$t('network')"
        :options="net"
        size="medium"
        :preselect-first="true"
        :allow-empty="false"
        class="resize-row-input"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'NewRouter',
  components: { BaseInput, BaseSelect },
  mixins: [setFocus],
  props: {
    instance: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        name: '',
      },
      value: '',
    };
  },
  computed: {
    net() {
      return this.instance.map(x => x.name);
    },
  },
  mounted() {
    this.$emit('notready');
  },
  methods: {
    netId(name) {
      return name ? this.instance.find(x => x.name === name).id : null;
    },
    onChange(event) {
      Object.assign(this.formData, event);
      if (this.formData.name) {
        return this.$emit('change', {
          router: {
            name: this.formData.name,
            external_gateway_info: {
              network_id: this.netId(this.value),
            },
          },
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": "Имя роутера:",
    "placeholder": "роутер",
    "network": "Сеть:"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
  &-input {
     margin-top: 0.5rem;
}
  }
}
</style>
