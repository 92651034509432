var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"router"},[(_vm.isRequest)?_c('div',{staticClass:"router__loader"},[_c('base-loader',{staticClass:"router__loader-item"})],1):(!_vm.isRequest)?_c('page-block',{staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"router-info__content"},[_c('page-title',{staticClass:"router-info__title"},[_vm._v(" "+_vm._s(_vm.$t('title.server'))+" ")]),_c('div',{staticClass:"router-info__create",class:{ visible: true }},[_c('base-button',{staticClass:"router-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
            content: _vm.text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          },"color":_vm.isColor,"disabled":_vm.isRequest},on:{"click":function($event){_vm.isRouters !== 0 ? _vm.newRouter() : ''}}},[_vm._v(_vm._s(_vm.$t('newRouter')))])],1)],1),(_vm.list.length === 0)?_c('base-empty',{staticClass:"cloud-info__empty",attrs:{"title":"Новая приватная сеть"}},[_c('base-button',{attrs:{"slot":"link"},slot:"link"},[_vm._v(" "+_vm._s(_vm.$t('newRouter'))+" ")])],1):_vm._e(),_c('tariffs-table-router')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }