import NewPublicNetwork from '@/layouts/Stack/components/NewPublicNetwork';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    newPublicNetwork: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'NewPublicNetwork';
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: NewPublicNetwork,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              // console.log(instance);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            // notready: () => {
            //   Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            // },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.addNewPublicNetwork(instance)
                    .then(async data => {
                      // console.log(data);
                      await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
                      if (data && data.result === 'ok') {
                        setTimeout(
                          async () =>
                            await this.$store.dispatch(
                              'moduleStack/fetchOpenStackRequest',
                              'networks'
                            ),
                          1000
                        ); // сделать зацикленным запрос, пока не появятся заполненные поля
                        this.fetchServer();
                        that.$modals.close();
                        // this.showResModal(this.$t('success', { msg: 'qwqwqw' }));
                        this.showResModal('Публичная подсеть успешно создана');
                      } else {
                        that.$modals.close();
                        this.showError(data.message);
                      }
                    })
                    .catch(e => {
                      // console.log(e);
                      that.$modals.close();
                      this.showError(e.response.data);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewPublicNetwork(prefix) {
      return this.$store.dispatch('moduleStack/createPublicNetwork', {
        id: this.id,
        prefix: prefix,
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
