<template>
  <div class="resize">
    <div class="resize-row medium-text">
      <base-select
        v-model="size"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :options="prefixesPublic"
        placeholder="Выберите размер подсети"
        size="medium"
        field-label="Префикс"
        :hint="'Размер публичной подсети'"
        :required="true"
        :preselect-first="true"
        :allow-empty="false"
        class="new-disk__services"
        @input="onChange"
      ></base-select>
    </div>
    <div class="resize-row medium-text">
      {{ $t('cost', { msg: $n(cost, 'currency', this.$i18n.locale) }) }}
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import BaseSelect from '@/components/Select/BaseSelect';
export default {
  name: 'NewPublicNetwork',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      size: 29,
    };
  },
  computed: {
    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    prefixesPublic() {
      return this.$store.state.moduleStack.prefix;
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === `network_${this.size}`)[
            this.dev
          ]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
  },
  watch: {},
  mounted() {
    this.$emit('change', this.size);
  },
  methods: {
    onChange(event) {
      // console.log('change');
      this.$emit('change', event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "sure": {
      "confirm": "Увеличить"
    },
    "cost": "Стоимость использования публичной сети составит %{msg} / час",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
